import React  from "react"
import { FormspreeProvider } from '@formspree/react';

//styles
import "../styles/index.css"
import "../styles/header.css"
import "../styles/banner.css"
import "../styles/footer.css"
import "../styles/sbanner.css"
import "../styles/services.css"
import "../styles/servicesTemp.css"
import "../styles/contact.css"
import "../styles/careers.css"
import "../styles/serviceCard.css"
import "../styles/ourStory.css"
import "../styles/blog.css"

//testing. remove later
// components
import Header from "../components/Header.js";
import Banner from "../components/Banner.js"
import Footer from "../components/Footer.js";
import ServiceCard from "../components/ServiceCard.js";

// components
import Layout from "../components/Layout";
import ContactForm from "../components/ContactForm.js";

const contact = () => {
  return (
   <Layout title="Contact Us" path="/ Contact Us">
      <title>Co-op Homecare | Contact Us</title>
      <div className='center-top'>
        <FormspreeProvider project="1885643879038844172">
          <ContactForm/>
        </FormspreeProvider>
        <div id="contactSide">
          <div>
            <h2>Our Location</h2>
            <p>979 F Street, Suite A-1 <br></br>Davis, CA 95616</p>
          </div>
        
          <div>
            <h2>Direct Contact</h2>
            <p>Phone: 530-746-5730 <br></br>Email: info@coophomecare.coop</p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default contact

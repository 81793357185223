import React from 'react';
import { useForm, ValidationError } from '@formspree/react';

function ContactForm() {
  const [state, handleSubmit] = useForm("contactForm");
  if (state.succeeded) {
      return (
        <div className='center-top'>
          <div id="thanks-container">
              <h2 id="thanks">Thank you for your interest! <br></br> We will be responding to you shortly.</h2>
              <p>Please feel free to explore the rest of our site.</p>
          </div>
  
      </div>
      
      );
  }
  return (
    <div id="form-container">
        <form onSubmit={handleSubmit}>
        <label htmlFor="name">
          Name
        </label>
        <input
          id="name"
          type="text" 
          name="name"
        />
        <label htmlFor="phoneIn">
          Phone Number
        </label>
        <input
          id="phoneIn"
          type="tel" 
          name="phoneIn"
        />
        <label htmlFor="zip">
          Zip Code
        </label>
        <input
          id="zip"
          type="text" 
          name="zip"
        />
        <label htmlFor="email">
          Email Address
        </label>
        <input
          id="email"
          type="email" 
          name="email"
        />
        <ValidationError 
          prefix="Email" 
          field="email"
          errors={state.errors}
        />
        <label htmlFor="message">
          Message
        </label>
        <textarea
          id="message"
          name="message"
        />
        <ValidationError 
          prefix="Message" 
          field="message"
          errors={state.errors}
        />
        <button type="submit" disabled={state.submitting}>
          Send
        </button>
      </form>
    </div>
    
  );
}
export default ContactForm;